import { Component, OnInit, inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { take } from 'rxjs'
import { selectUser } from 'src/app/state/user.reducer'

@Component({
  selector: 'app-goto-saved-search',
  standalone: true,
  imports: [],
  templateUrl: './goto-saved-search.component.html',
  styleUrls: ['./goto-saved-search.component.scss']
})
export class GotoSavedSearchComponent implements OnInit {
  private store = inject<Store<any>>(Store)
  private route = inject(ActivatedRoute)
  private router = inject(Router)


  ngOnInit(): void {
    this.store.select(selectUser).pipe(take(1)).subscribe(user => {
      const alertID = this.route.snapshot.paramMap.get('alertid') ?? ''

      if (user !== undefined && alertID !== '') {
        for (let [area, areaSavedSearch] of Object.entries(user!.searches)) {
          for (const search of areaSavedSearch) {
            if (search.id === alertID) {
              if (area === 'data') {
                area = search.parameters.area ?? area
              }
              this.router.navigateByUrl('/' + area, {
                state: search.parameters
              })
              return
            }
          }
        }
      }
    })
  }
}