import { NgModule, inject } from '@angular/core'
import { CanActivateChildFn, RouterModule, Routes, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { select, Store } from "@ngrx/store"
import { BreakpointService } from '@lib/master-detail'
import { ListItemComponent } from './shared/components/list-item/list-item.component'
import { newsSteps, newsDefaultStepOptions } from './pages/news/'
import { of, tap, switchMap } from 'rxjs'
import { isLoggedIn } from './state/user.reducer'
import { LoginComponent } from './pages/login/login.component'
import { loginComplete, setReturnToURL } from './state/user.actions'
import { CookieService } from 'ngx-cookie-service'
import { AuthService } from './services/auth.service'
import { InstrumentArticleComponent } from './pages/data/instruments/instrument-article/instrument-article.component'
import { PlanArticleComponent } from './pages/data/plans/plan-article/plan-article.component'
import { AssetArticleComponent } from './pages/data/asset-sales/asset-article/asset-article.component'
import { ForgottenPasswordComponent } from './pages/forgotten-password/forgotten-password.component'
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component'
import { LegacyLoginComponent } from './pages/legacy-login/legacy-login.component'
import { GotoSavedSearchComponent } from './pages/goto-saved-search/goto-saved-search.component'
import { PublicPageComponent } from './pages/public-page/public-page.component'
import { UnsubscribePageComponent } from './pages/unsubscribe-page/unsubscribe-page.component'
import { ChangePasswordComponent } from './pages/change-password/change-password.component'
import { ActivateUserComponent } from './pages/activate-user/activate-user.component'
import { ImpersonateUserComponent } from './pages/impersonate-user/impersonate-user.component'
import { CreditorArticleComponent } from './pages/data/creditors/creditor-article/creditor-article.component'
import { documentSearchOptionGroups } from './pages/documents/document-search-setup'
import { secDocumentSearchOptionGroups } from './pages/sec-documents/sec-document-search-setup'

const activateOnMobileOrNoListing: CanActivateChildFn =  (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const breakPointService = inject(BreakpointService)
	const router = inject(Router)
	// second condition is to push into search e.g. http://localhost:4200/news/1211867?entity_name=1225001:Strudel%20Holdings%20LLC
	const result = ! breakPointService.isDesktop  || (window.location.search.length === 0 && router.routerState.snapshot.url === '') || (window.location.search.length > 0 && window.location.search.includes('content=') && window.location.pathname.includes('/organizations'))
	return result
}



export const isLoggedInGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const store = inject(Store)
	const router = inject(Router)
	const cookieService = inject(CookieService)
	const authService = inject(AuthService)

	const hasTokenCookie = cookieService.get('token')?.length == 36
	if (hasTokenCookie) {
		return store.pipe(
			select(isLoggedIn),
			switchMap(isLoggedIn => {
				if (isLoggedIn) {
					return of(true)
				} else {
					return authService.validateTokenCookie().pipe(tap((validationResult:any) => {
						if (validationResult.loggedIn) {
							store.dispatch(setReturnToURL({
								urlAfterLogin: state.url
							}))
							store.dispatch(loginComplete(validationResult))
							return of(true)
						} else {
							if (state.url.includes('/public')) {
								return of(true)
							}
							store.dispatch(setReturnToURL({
								urlAfterLogin: state.url
							}))
							router.navigateByUrl('/login')
							return of(false)
						}
					}))
				}
			})
		)
	} else {
		if (state.url.includes('/public')) {
			return of(true)
		}
		store.dispatch(setReturnToURL({
			urlAfterLogin: state.url
		}))
		router.navigateByUrl('/login')
		return of(false)
	}
}

export const applicationRoutes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		title: 'Login | BankruptcyData'
	},
	{
		path: 'forgottenpassword/:token',
		component: ForgottenPasswordComponent,
	},
	{
		path: 'changepassword/:token',
		component: ChangePasswordComponent,
	},
	{
		path: 'activate/:contactID/:email',
		component: ActivateUserComponent,
	},
	{
		path: 'impersonate/:token',
		component: ImpersonateUserComponent,
	},
	{
		path: 'legacy/:jwt',
		component: LegacyLoginComponent,
	},
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'home'
	},

	{
		path: 'home',
		loadComponent: () =>
			import('./pages/home/home.component').then((x) => x.HomeComponent),

		title: 'Home | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
	},
	{
		path: 'public',
		component: PublicPageComponent,
		title: 'Public Page | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
	},
	{
		path: 'public-unsubscribe',
		component: UnsubscribePageComponent,
		title: 'Unsubscribe | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
	},
	{
		path: 'search/:alertid',
		component: GotoSavedSearchComponent,
		title: 'Saved Search | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
	},

	{
		path: 'analytics/reports',
		loadComponent: () => import('./pages/analytics/bankruptcy-reports/bankruptcy-reports.component').then((x) => x.BankruptcyReportsComponent),

		title: 'Bankruptcy Reports | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
	},
	{
		path: 'analytics/trends',
		loadComponent: () => import('./pages/analytics/bankruptcy-trends/bankruptcy-trends.component').then((x) => x.BankruptcyTrendsComponent),

		title: 'Bankruptcy Trends | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
	},
	{
		path: 'analytics',
		loadComponent: () => import('./pages/analytics/analytics.component').then((x) => x.AnalyticsComponent),
		title: 'Analytics | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},
	{
		path: 'news/newsletter/:newsletterid/:id',
		canMatch: [ activateOnMobileOrNoListing ],
		canActivate: [ isLoggedInGuard ],
		loadComponent: () => import('./pages/news/news-article/news-article.component').then((x) => x.NewsArticleComponent),
		data: {
			contentSet: 'news/newsletter',
		}
	},
	{
		path: 'news/newsletter/:newsletterid',
		loadComponent: () => import('./pages/news/newsletter-article/newsletter-article.component').then((x) => x.NewsletterArticleComponent),


		canActivate: [ isLoggedInGuard ],
		data: {
			listComponent: ListItemComponent,
			contentSet: 'news/newsletter',
		},
		title: 'Newsletter | BankruptcyData',
		children: [
			{
				path: ':id',
				loadComponent: () => import('./pages/news/news-article/news-article.component').then((x) => x.NewsArticleComponent),
				data: {
					contentSet: 'news/newsletter',
				}
			},
		],
	},
	{
		path: 'news/newsletter',
		loadComponent: () =>
		import('./pages/news/newsletter-listing/newsletter-listing.component').then((x) => x.NewsletterListingComponent),
		data: {
			contentSet: 'news/newsletters',
		},
		canActivate: [ isLoggedInGuard ],
		title: 'Newsletters | BankruptcyData',
	},


	{
		path: 'news/:id',
		loadComponent: () => import('./pages/news/news-article/news-article.component').then((x) => x.NewsArticleComponent),

		title: 'News | BankruptcyData',
		canMatch: [ activateOnMobileOrNoListing ],
		canActivate: [ isLoggedInGuard ],
		data: {
            contentSet: 'news',
		}
	},
	{
		path: 'news',
		loadChildren: () =>
			import('./pages/news/news-routes').then((x) => x.NewsModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ]
	},
	{
		path: 'reports/:id',
		loadComponent: () => import('./pages/news/news-article/news-article.component').then((x) => x.NewsArticleComponent),

		title: 'Reports | BankruptcyData',
		canMatch: [ activateOnMobileOrNoListing ],
		canActivate: [ isLoggedInGuard ],
		data: {
            contentSet: 'reports',
		}
	},
	{
		path: 'reports',
		loadChildren: () =>
			import('./pages/reports/reports-routes').then((x) => x.ReportsModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ]
	},


	{
		path: 'situations/:id',
		loadComponent: () => import('./pages/situations/situations-article/situations-article.component').then((x) => x.SituationComponent),

		title: 'Situations | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
		canMatch: [ activateOnMobileOrNoListing ],
		data: {
            contentSet: 'situations',
		}
	},
	{
		path: 'situations',
		loadChildren: () => import('./pages/situations/situations-routes').then((x) => x.SituationsModule),

		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ]
	},
	{
		path: 'organizations/:id',
		loadComponent: () => import('./pages/organizations/organizations-article/organization-article.component').then((x) => x.OrganizationComponent),
		title: 'Organizations | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
		canMatch: [ activateOnMobileOrNoListing ],
		data: {
            contentSet: 'organizations',
		}
	},
	{
		path: 'organizations',
		loadChildren: () => import('./pages/organizations/organizations-routes').then((x) => x.OrganizationsModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ]
	},
	{
		path: 'people/:id',
		loadComponent: () =>
			import('./pages/people/person-article/person-article.component').then((x) => x.PersonComponent),
		title: 'People | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
		canMatch: [ activateOnMobileOrNoListing ],
		data: {
            contentSet: 'people',
		}
	},
	{
		path: 'people',
		loadChildren: () => import('./pages/people/people-routes').then((x) => x.PeopleModule),

		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ]
	},
	/*
	{
		path: 'data/instruments/:id',
		component: InstrumentArticleComponent,
		title: 'Instruments | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
		canMatch: [ activateOnMobileOrNoListing ],
		data: {
            contentSet: 'instruments',
		}
	},
	*/

	{
		path: 'data/calendar',
		loadChildren: () => import('./pages/data/calendar/calendar-routes').then((x) => x.CalendarsModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},
	{
		path: 'data/instruments',
		loadChildren: () => import('./pages/data/instruments/instruments-routes').then((x) => x.InstrumentsModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},
	{
		path: 'data/plans',
		loadChildren: () => import('./pages/data/plans/plans-routes').then((x) => x.PlansModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},
	{
		path: 'data/asset-sales',
		loadChildren: () => import('./pages/data/asset-sales/asset-sales-routes').then((x) => x.AssetSalesModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},

	{
		path: 'data/creditors/:id',
		loadComponent: () => import('./pages/data/creditors/creditor-article/creditor-article.component').then((x) => x.CreditorArticleComponent),

		title: 'Creditors | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
		canMatch: [ activateOnMobileOrNoListing ],
		data: {
            contentSet: 'situations',
		}
	},


	{
		path: 'data/creditors',
		loadChildren: () => import('./pages/data/creditors/creditors-routes').then((x) => x.CreditorsModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},
	{
		path: 'data/creditors2',
		loadChildren: () => import('./pages/data/creditors2/creditors-routes').then((x) => x.CreditorsModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},
	{
		path: 'data/groups',
		loadChildren: () => import('./pages/data/groups/groups-routes').then((x) => x.GroupsModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},
	{
		path: 'data/keip-kerp',
		loadChildren: () => import('./pages/data/keip-kerp/keip-kerp-routes').then((x) => x.KeipKerpRoutesModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},
	{
		path: 'data/retentions',
		loadChildren: () => import('./pages/data/retentions/retentions-routes').then((x) => x.RetentionModule),
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},
	{
		path: 'data/archive',
		loadComponent: () =>
			import('./pages/data/archive/archive.component').then((x) => x.ArchiveComponent),

		data: {
			contentSet: 'data/archive',
			reuseComponent: false,
			sections: []
		},
		title: 'Archive | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
	},
	/*
	{
		path: 'data',
		component: DataComponent,
		title: 'Data Sets | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ],
		data: {
		},
	},
	*/
	{
		path: 'documents',
		loadComponent: () =>
			import('./pages/documents/documents.component').then((x) => x.DocumentsComponent),
		data: {
			reuseComponent: false,
			searchOptionGroups: documentSearchOptionGroups,
			shepherdIntro: [newsDefaultStepOptions, newsSteps],
            contentSet: 'documents',
		},
		title: 'Documents | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ]
	},
	{
		path: 'secdocuments',
		loadComponent: () => import('./pages/sec-documents/sec-documents.component').then((x) => x.SECDocumentsComponent),
		data: {
			reuseComponent: false,
			searchOptionGroups: secDocumentSearchOptionGroups,
			shepherdIntro: [newsDefaultStepOptions, newsSteps],
            contentSet: 'secdocuments',
		},
		title: 'SEC Documents | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ]
	},
	{
		path: 'league-tables',
		loadComponent: () => import('./pages/league-tables/league-tables-page.component').then((x) => x.LeagueTablesPageComponent),
		title: 'League Tables | BankruptcyData',
		canActivate: [ isLoggedInGuard ],
	},


	{
		path: 'my-account',
		loadComponent: () => import('./pages/my-account/my-account.component').then((x) => x.MyAccountComponent),

		canActivate: [ isLoggedInGuard ],
		title: 'My Account | BankruptcyData',
		data: {
		},
	},

	{
		path: 'my-account/usage',
		loadChildren: () => import('./pages/my-account/usage-routes').then((x) => x.UsageModule),

		canActivate: [ isLoggedInGuard ],
		canActivateChild: [ isLoggedInGuard ]
	},

	{
		path: 'print/news',
		loadComponent: () => import('./pages/news/news-print/news-print.component').then((x) => x.NewsPrintComponent),
		canActivate: [ isLoggedInGuard ],
	},
	{
		path: 'print/reports',
		loadComponent: () => import('./pages/news/news-print/news-print.component').then((x) => x.NewsPrintComponent),
		canActivate: [ isLoggedInGuard ],
	},
	{
		path: 'print/news/newsletter/:id',
		loadComponent: () => import('./pages/news/news-print/news-print.component').then((x) => x.NewsPrintComponent),
		canActivate: [ isLoggedInGuard ],
	},
	{
		path: 'print/news/newsletter',
		loadComponent: () => import('./pages/news/news-print/news-print.component').then((x) => x.NewsPrintComponent),
		canActivate: [ isLoggedInGuard ],
	},
	{
		path: 'print/situations',
		loadComponent: () => import('./pages/situations/situations-print/situations-print.component').then((x) => x.SituationsPrintComponent),
		canActivate: [ isLoggedInGuard ],
	},
	{
		path: 'print/data/instruments',
		loadComponent: () =>
			import('./shared/components/articles-print/articles-print.component').then((x) => x.ArticlesPrintComponent),
		canActivate: [ isLoggedInGuard ],
		data: {
			articleComponent: InstrumentArticleComponent,
		}
	},
	{
		path: 'print/data/plans',
		loadComponent: () => import('./shared/components/articles-print/articles-print.component').then((x) => x.ArticlesPrintComponent),

		canActivate: [ isLoggedInGuard ],
		data: {
			articleComponent: PlanArticleComponent,
		}
	},
	{
		path: 'print/data/creditors',
		loadComponent: () => import('./shared/components/articles-print/articles-print.component').then((x) => x.ArticlesPrintComponent),

		canActivate: [ isLoggedInGuard ],
		data: {
			articleComponent: CreditorArticleComponent,
		}
	},
	{
		path: 'print/data/asset-sales',
		loadComponent: () => import('./shared/components/articles-print/articles-print.component').then((x) => x.ArticlesPrintComponent),
		canActivate: [ isLoggedInGuard ],
		data: {
			articleComponent: AssetArticleComponent,
		}
	},
	{
		path: '**',
		component: PageNotFoundComponent
	}

]

@NgModule({
	imports: [RouterModule.forRoot(applicationRoutes, {
		enableTracing: false,
		onSameUrlNavigation: 'reload'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule {

}